import { BookingsGroup } from '../../types';
import { isBookingPaidWithPricingPlan } from '../../utils/payment';
import { CreateActionParams } from '../actions';
import _ from 'lodash';

export type ClickOnCancel = ({ group }: { group: BookingsGroup }) => void;

export function createClickOnCancelAction({
  context,
  getControllerState,
}: CreateActionParams): ClickOnCancel {
  return async ({ group }) => {
    const { api, flowAPI } = context;
    const [state, setState] = getControllerState();
    const { t } = flowAPI.translations;

    const upcomingData = _.cloneDeep(state.upcomingData);
    const groups = state.upcomingData?.groups || [];

    const modifiedGroups: BookingsGroup[] = groups.filter((bookingGroup) => {
      if (group.groupId) {
        return bookingGroup.groupId !== group.groupId;
      } else {
        return (
          bookingGroup.bookings[0].bookingId !== group.bookings[0].bookingId
        );
      }
    });

    setState({
      upcomingData: {
        ...state.upcomingData,
        groups: modifiedGroups,
        relatedOfferings: state.upcomingData?.relatedOfferings || [],
        relatedStaff: state.upcomingData?.relatedStaff || [],
      },
    });

    try {
      if (group.groupId) {
        await api.cancelGroup({ groupId: group.groupId });
      } else {
        await api.cancelSingle({
          bookingId: group.bookings[0].bookingId,
          bookingRevision: group.bookings[0].revision,
          withRefund: isBookingPaidWithPricingPlan(group),
        });
      }

      setState({
        toastContext: {
          skin: 'success' as any,
          content: t(
            'app.my-bookings-widget.cancellation-modal.toast-success-message.label',
          ),
        },
      });
    } catch (e) {
      setState({
        upcomingData,
        toastContext: {
          skin: 'error' as any,
          content: t(
            'app.my-bookings-widget.cancellation-modal.toast-error-message.label',
          ),
        },
      });
    }
  };
}
