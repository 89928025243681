import { mockService } from './mockService';
import StaffBuilder from './mockStaff';
import AllowedActionsBuilder from './mockAllowedActions';
import {
  BookingDTO,
  BookingStatus,
  BookingsGroup,
  ServiceTypes,
  VideoConference,
  StaffDTO,
} from '../../src/types';

function getTimestamp(dateInstance = new Date()) {
  return dateInstance.getTime();
}

export const mapBookingsToGroups: (booking: BookingDTO) => BookingsGroup = (
  booking,
) => ({
  allowedActions: booking.allowedActions,
  bookings: [booking],
});

const { id: serviceId } = mockService();
const { id: staffId, fullName, image } = StaffBuilder().build();

const validBookingDTO = (): BookingDTO => {
  return {
    allowedActions: AllowedActionsBuilder().build(),
    bookingId: '123-456-789',
    contactId: '234-2343-2343',
    end: 0,
    groupInstanceId: '',
    locationInfo: 'my place',
    numOfParticipants: 0,
    offeringId: serviceId,
    service: mockService({ id: serviceId }),
    payed: 50,
    parentOfferingId: '',
    staffId,
    start: getTimestamp(new Date()),
    pricingPlanName: null,
    bookedAs: 'ONE_TIME',
    totalPrice: 109,
    totalPriceAfterDiscount: 109,
    currency: 'USD',
    status: BookingStatus.CONFIRMED,
    payment: {
      currency: 'USD',
      price: 109, // unit * numOfParticipants
      priceAfterDiscount: 109, //  //unit * numOfParticipants - (discount/%discount)
      type: 'paymentWithDiscountAllow',
    },
    staffInfo: {
      fullName,
      id: staffId,
      image,
    },
  };
};

function getRandomId(): string {
  const rand: number = Math.random() * 100000;
  return Math.round(rand).toString();
}

export default function BookingBuilder() {
  let bookingDTO = validBookingDTO();

  return {
    build: ({
      serviceID = mockService().id,
      staffID = StaffBuilder().build().id,
      bookingID = getRandomId(),
    }: {
      serviceID?: string;
      staffID?: string;
      bookingID?: string;
    } = {}) => {
      return {
        ...bookingDTO,
        bookingId: bookingID,
        offeringId: serviceID,
        staffId: staffID,
      };
    },
    withVideoConference(videoConference: VideoConference) {
      bookingDTO.videoConference = videoConference;
      return this;
    },
    withStatus(status: BookingStatus) {
      bookingDTO.status = status;
      return this;
    },
    withPayment(payment: any) {
      bookingDTO.payment = payment;
      return this;
    },
    withServiceId(serviceID: string) {
      bookingDTO.offeringId = serviceID;
      return this;
    },
    withService(service = mockService()) {
      bookingDTO.service = service;
      return this;
    },
    withStaffInfo(staffInfo: StaffDTO) {
      bookingDTO.staffId = staffInfo.id;
      bookingDTO.staffInfo = staffInfo;
      return this;
    },
    withRescheduleAllowed() {
      bookingDTO.allowedActions.reschedule = true;
      return this;
    },
    withRescheduleNotAllowed() {
      bookingDTO.allowedActions.reschedule = false;
      return this;
    },
    withCancelAllowed() {
      bookingDTO.allowedActions.cancel = true;
      return this;
    },
    withCancelNotAllowed() {
      bookingDTO.allowedActions.cancel = false;
      return this;
    },
    withBookAnotherAllowed() {
      bookingDTO.allowedActions.bookAnother = true;
      return this;
    },
    withBookAnotherNotAllowed() {
      bookingDTO.allowedActions.bookAnother = false;
      return this;
    },
    withGroupService() {
      const serviceDTO = mockService({ type: ServiceTypes.GROUP });
      bookingDTO.offeringId = serviceDTO.id;
      bookingDTO.service = serviceDTO;
      return this;
    },
    withIndividualService() {
      const serviceDTO = mockService({ type: ServiceTypes.INDIVIDUAL });
      bookingDTO.offeringId = serviceDTO.id;
      bookingDTO.service = serviceDTO;
      return this;
    },
    withCourseService() {
      const serviceDTO = mockService({ type: ServiceTypes.COURSE });
      bookingDTO.offeringId = serviceDTO.id;
      bookingDTO.service = serviceDTO;
      return this;
    },
    withLocationInfo(location: string) {
      bookingDTO.locationInfo = location;
      return this;
    },
    withStart(start = new Date()) {
      bookingDTO.start = getTimestamp(start);
      return this;
    },
    withEnd(end = new Date()) {
      bookingDTO.end = getTimestamp(end);
      return this;
    },
    withPricingPlanName(name: string | null) {
      bookingDTO.pricingPlanName = name;
      bookingDTO.bookedAs = 'PRICING_PLAN';
      return this.withRescheduleNotAllowed();
    },
    withRevision(revision?: any) {
      bookingDTO.revision = revision;
      return this;
    },
    withNumberOfParticipants(numOfParticipants: number) {
      bookingDTO.numOfParticipants = numOfParticipants;
      return this;
    },
    withDynamicPriceInfo(dynamicPriceInfo?: string) {
      bookingDTO.dynamicPriceInfo = dynamicPriceInfo;
      return this;
    },
    withIsDynamicPrice(isDynamicPrice: boolean) {
      bookingDTO.isDynamicPrice = isDynamicPrice;
      return this;
    },
    withGroupId(groupId: string) {
      bookingDTO.groupId = groupId;
      return this;
    },
    buildWith(obj?: Partial<BookingDTO>) {
      bookingDTO = { ...bookingDTO, ...obj };
      return this;
    },
  };
}
