import BookingBuilder from './mockBooking';
import { mockService } from './mockService';
import StaffBuilder from './mockStaff';
import { Bookings, BookingStatus, ServiceTypes } from '../../src/types';
import { mockGroup } from './mockGroup';

export const mockMemberAreaBookings = ({
  groups,
  relatedOfferings = [
    mockService(),
    mockService({ name: 'second service', type: ServiceTypes.GROUP }),
    mockService({ name: 'my first course', type: ServiceTypes.COURSE }),
    mockService({ name: 'my first plan' }),
  ],
  relatedStaff = [StaffBuilder().build()],
}: Partial<Bookings> = {}): Bookings => ({
  groups: groups || [
    mockGroup({
      bookings: [
        BookingBuilder().withVideoConference({ link: 'wix.com' }).build({
          serviceID: mockService().id,
          staffID: relatedStaff[0].id,
        }),
        BookingBuilder().build({
          serviceID: mockService().id,
          staffID: relatedStaff[0].id,
        }),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withVideoConference({ link: 'wix.com' })
          .build({
            serviceID: mockService({ name: 'second service' }).id,
            staffID: relatedStaff[0].id,
          }),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withVideoConference({ link: 'wix.com', password: '123abc' })
          .build({
            serviceID: mockService({ name: 'my first course' }).id,
            staffID: relatedStaff[0].id,
          }),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withPricingPlanName('plan name')
          .withStatus(BookingStatus.PENDING_APPROVAL)
          .build({
            serviceID: mockService({ name: 'my first plan' }).id,
            staffID: relatedStaff[0].id,
          }),
      ],
    }),
    mockGroup({
      bookings: [
        BookingBuilder()
          .withPricingPlanName('second plan name')
          .withStatus(BookingStatus.PENDING)
          .build({
            serviceID: mockService({ name: 'my first plan' }).id,
            staffID: relatedStaff[0].id,
          }),
      ],
    }),
  ],
  relatedOfferings,
  relatedStaff,
});
