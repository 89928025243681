import { UserInfo } from '../../src/types';

export const mockUserInfo = ({
  locale = 'he-il',
  timezone = 'America/New_York',
}: Partial<UserInfo> = {}): UserInfo => {
  return {
    locale,
    timezone,
  };
};
