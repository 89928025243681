import { BusinessInfo, BusinessTimezoneTypes } from '../../src/types';

export const mockBusinessInfo = ({
  dateRegionalSettingsLocale = 'en',
  businessTimezone = 'America/New_York',
  defaultTimezoneToUse = BusinessTimezoneTypes.BUSINESS,
  allowTimezoneSwitch = true,
}: Partial<BusinessInfo> = {}): BusinessInfo => {
  return {
    dateRegionalSettingsLocale,
    businessTimezone,
    defaultTimezoneToUse,
    allowTimezoneSwitch,
  };
};
