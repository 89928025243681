import { ServiceDTO, ServiceTypes } from '../../src/types';

export const mockService = ({
  currency = 'USD',
  id = '123',
  image = null,
  name = 'my service name',
  paymentType = 'ALL',
  price = 100,
  priceText = null,
  isFree = false,
  type = ServiceTypes.INDIVIDUAL,
  isBookingApprovalFlow = false,
  urlName = 'service-slug',
}: Partial<ServiceDTO> = {}): ServiceDTO => {
  return {
    currency,
    id,
    image,
    name,
    paymentType,
    price,
    priceText,
    isFree,
    type,
    isBookingApprovalFlow,
    urlName,
  };
};
