import { BusinessTimezoneTypes, SelectedTimezone } from '../../src/types';

export const mockSelectedTimezone = ({
  type = BusinessTimezoneTypes.BUSINESS,
  timezone = 'America/New_York',
}: Partial<SelectedTimezone> = {}): SelectedTimezone => {
  return {
    type,
    timezone,
  };
};
