import { BookingsGroup } from '../../src/types';
import AllowedActionsBuilder from './mockAllowedActions';
import BookingBuilder from './mockBooking';

export const mockGroup = ({
  allowedActions = AllowedActionsBuilder().build(),
  bookings = [
    BookingBuilder().build({
      serviceID: 'some-service-id',
      staffID: 'some-staff-id',
    }),
  ],
  groupId = 'some-group-id',
  order,
  transaction,
}: Partial<BookingsGroup> = {}): BookingsGroup => ({
  allowedActions,
  bookings,
  groupId,
  ...(order ? { order } : {}),
  ...(transaction ? { transaction } : {}),
});
